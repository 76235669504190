import { SectionMenu } from 'components';
import { Types } from 'common';

enum DateFilterOptionEnum {
	FORTHCOMING = 'FORTHCOMING',
	ONDEMAND = 'ONDEMAND'
}

const dateFilterMenuOptions: Array<SectionMenu<DateFilterOptionEnum>> = [
	{
		id: DateFilterOptionEnum.FORTHCOMING,
		localeId: 'catalog.home.forth-coming.menu'
	},
	{
		id: DateFilterOptionEnum.ONDEMAND,
		localeId: 'catalog.home.ondemand.menu'
	}
];

/*
Disable date filter for certain content types and brands
 */
export const getIsDateFilterVisible = (
	contentTypeFilter: Types.ProductBrand | Types.ContentType | 'ALL' | null | undefined
) =>
	contentTypeFilter !== 'ALL' &&
	contentTypeFilter !== Types.ProductBrand.Summedup &&
	contentTypeFilter !== Types.ProductBrand.Skill &&
	contentTypeFilter !== Types.ProductBrand.Guideline &&
	contentTypeFilter !== Types.ContentType.Lecture;

const dateFilterOptions = [DateFilterOptionEnum.FORTHCOMING, DateFilterOptionEnum.ONDEMAND];

export { dateFilterMenuOptions, dateFilterOptions, DateFilterOptionEnum };
